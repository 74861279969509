import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../../Components/Common/Header";

export const AdmissionProcedure = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../../assets/imgs/bg/mgmu.jpg?v=0.0.4")}`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    Admission Procedure
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 mb-5">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Steps of Admission Procedure</h2>
                  <div
                    className="job__detail-content"
                    style={{ paddingTop: "0" }}
                  >
                    <h3 className="mt-3">1. Apply Online </h3>
                    <p>
                      The student aspiring to take admission at MGM School of
                      Film Arts is supposed to apply by filling out the
                      admissions form at the following link.
                    </p>
                    <h3 className="mt-3">Admission form</h3>
                    <p>
                      The application will be confirmed once the student pays
                      the application fees (Rs. 1500/-) included in the form
                      procedure.
                    </p>

                    <h3 className="mt-3">2. CAPT Test</h3>
                    <p>
                      After confirmation of your application, our counselors
                      will contact you to inform you about the online Creative
                      Aptitude Test (CAPT).
                    </p>
                    <p>
                      The student is supposed to gain a passing grade to claim
                      their seat at the MGM School of Film Arts.
                    </p>

                    <h3 className="mt-3">3. Online Interview</h3>
                    <p>
                      After the CAPT Test, the student is supposed to face an
                      online interview with our Mentors and Faculty.
                    </p>
                    <p>
                      The result of the interview will be announced 24 hours
                      after the interview.
                    </p>
                    <h3 className="mt-3">4. Document Verification</h3>
                    <p>
                      Once the student completes the procedure, they are
                      supposed to submit their documents at the Administrator
                      Office of MGM's College of Journalism and Mass
                      Communication.
                    </p>
                    <ul>
                      <li>
                        <strong>Documents required:</strong>
                      </li>
                      <li>
                        + Original TC (Applicable only for Graduation Programs,
                        others can submit a True copy)
                      </li>
                      <li>+ SSC Mark Memo</li>
                      <li>+ HSC Mark Memo</li>
                      <li>+ Aadhaar Card (True copy)</li>
                      <li>+ PAN Card (True copy)</li>
                      <li>
                        + Any other certificates of achievements (True copy)
                      </li>
                      <li>+ 4 Passport size Photos</li>
                    </ul>

                    <h3 className="mt-3">
                      5. Confirmation of Admission by Paying Fees
                    </h3>
                    <p>
                      After verification of documents, students are supposed to
                      pay their respective fees in the form of Demand Draft in
                      favor of MGM College of Journalism payable at Aurangabad
                      OR they can pay online.
                    </p>

                    <h3 className="mt-3">Admission form</h3>
                    <p>
                      For any difficulty in the admission process, please
                      contact Prof. Mahesh Harbak (9834602449/0240-2478908)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
