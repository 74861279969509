import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Accordion } from "react-bootstrap";
export const MaInFilmEditing = () => {
  return (
    <>
      {" "}
      <Header />
      <main>
        <section
          className="job__detail-top-editing pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/editing.png?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    MA In Film Editing
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper job__detail-wrapper-20">
                  <h2 className="sec-title sub-section-title">
                    MA with Major in Film Editing
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Our 2-year MA in Filmmaking (With Film Editing Major) is
                      designed to make our student excel in Editing for Films.
                      We offer professional film education in a practical
                      setting which is supported by our in-house studios. Our
                      teachers are industry professionals, working in film and
                      television.
                    </p>
                    <p>
                      Throughout the course you will gain a thorough knowledge
                      in the key areas of the process of film editing, from
                      script to screen, and become multi-skilled and adaptable,
                      favouring the art of collaborative storytelling rather
                      than working as a solo artist. You will learn how to
                      quickly adapt to the expectations of the director and that
                      will help you start and continue your career in the
                      industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is a perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Film Editor will excel in his craft only when he will
                      master all the filmmaking elements. An Editor must know
                      the camera and Lenses, Lighting Patterns, Dubbing, Vfx.
                      Hence we provide all standard filmmaking facilities to the
                      Acting student from day one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="0" id="accordionExample">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>Easy access to Editing, Vfx, Chroma Studios</li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Masterclass by Editors & Directors from Industry
                          </li>
                          <li>
                            Participation in compulsory classroom projects in
                            every semester
                          </li>
                          <li>
                            Chance to Edit a Full Length Feature Film in 4th
                            Semester as compulsory Feature Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 2 Years
                            (4 Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Eligibility</span>: Any
                            Graduate
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 12 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            150000 + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <a href="contact-us">Click here</a> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Student Feedback</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          I'm Aniket Ballal, student of MGM FILM ARTS, MGM
                          University. Here I'm studying a course called Acting
                          for Films. I would like to share my experience with
                          you.
                        </p>
                        <p>
                          “Kaal” - My first feature Short film. Basically
                          working in a professional film project with superstars
                          was a dream to me. I always wanted to be in a
                          professional project, and my dream came true only
                          because of MGM Film Arts.
                        </p>
                        <p>
                          So, When we first gathered to discuss the script we
                          learned that how a true industry project works. In
                          this discussion we learned that how should we put our
                          emotions in dialogues, how the dialogue delivery
                          should be, our Director Pratima Joshi told us each and
                          every thing very calmly.
                        </p>
                        <p>
                          Then on the first day of shooting we've started the
                          shooting with the Virtuous actress Mukta Barve. I was
                          amazed that how she was acting and behaving in a very
                          pleasent and professional manner. Her dialogues, her
                          expressions, everything was so impressive and
                          satisfying.
                        </p>
                        <p>
                          On the next day we got an opportunity to shoot the
                          further shortfilm with Veteran Actor Mohan Agashe sir.
                          When Mohan sir plays a character, it looks so fine and
                          satisfactory. As our Nandu Bhure sir (Acting Professor
                          MGM Film Arts) who taught us that how we should switch
                          between our characters(Original us and Character we
                          are playing) very immediately as an ON/OFF switch, so
                          we practically saw Mohan sir doing that flawlessly. I
                          was acting as a grandson of Mohan Agashe sir where
                          there was a scene of crying at that time I've used a
                          method stated by Stanislavsky called as Emotional
                          Memory. It helped me alot to get into my character and
                          hold the emotions.
                        </p>
                        <p>
                          And also it was a great experience to work with Milind
                          Jog sir, the cinematographer of many hit films such as
                          Luka-chuppi, Gulabjaam, Faster Fene, etc. He taught us
                          to be creative in our works.
                        </p>
                        <p>
                          It was my fortune that i've got a chance to work with
                          Actor & Director Chandrakant Kulkarni Sir and our HOD
                          Shiv Kadam sir.
                        </p>
                        <p>
                          I would really like to thank MGM University, MGM
                          School of Film Arts, Chancellor Ankushrao Kadam sir,
                          HOD Shiv Kadam sir, Professor Nandu Bhure sir,
                          Cinematographer Jaee Kadam mam and our whole team of
                          MGM FILM ARTS working for the short film.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. B. Lenin</span>{" "}
                            Director, Editor
                          </li>
                          <li>
                            <span className="fw-bold">2. Nitin Baid</span>{" "}
                            Editor
                          </li>
                          <li>
                            <span className="fw-bold">3. Shiv Kadam</span>{" "}
                            Director, Writer, Academician
                          </li>
                          <li>
                            <span className="fw-bold">4. Kalyani Shinde</span>{" "}
                            Editor
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. 5 Editing Studios</li>
                          <li>2. Vfx Lab</li>
                          <li>3. Chroma Studio</li>
                          <li>4. Shooting Floors</li>
                          <li>5. Shooting Locations</li>
                          <li>6. Filmmaking Equipments</li>
                          <li>7. Costume Lab</li>
                          <li>8. Make-up Lab</li>
                          <li>
                            9. <a href="facilities">Click here</a> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Prof. Mahesh Harbak </span> +91
                    9834602449
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
