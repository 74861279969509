import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
export const SoundDesignShortPrograms = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-sound pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/sound1.jpg?v=0.2.1) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Sound Design Short Programs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">
                    Basics of Music Production & Sound
                  </h2>
                  <div className="job__detail-content">
                    <p>6 Months Weekend Certificate Program</p>
                    <p>
                      Our 6 Months Basics weekend Certificate Program is one
                      step ahead of Introductory Certificate Program. Specially
                      designed for students who want join the Music Industry at
                      Superior level. Music Composer or Sound Designer of
                      Feature Film, TV Serials, Web Serieses requires an
                      reliable and skilled person who can handle all the key
                      managerial as well as creative aspects.{" "}
                    </p>
                    <p>
                      In this short but intensive program students will adapt
                      every skill required to become a good chief assistant to
                      the Music Producer, Sound Designer.{" "}
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practicing film & TV industry tutors
                          </li>
                          <li>Easy access to Dubbing, Editing Studios</li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>Masterclass by Actors from Industry </li>
                          <li>
                            Participation in compulsory classroom projects every
                            semester
                          </li>
                          <li>
                            Chance to Design and Execute Full-Length Feature
                            Film in Semester as compulsory Feature Project{" "}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 6 Months
                          </li>
                          <li>
                            <span className="fw-bold">Fees</span>: Rs. 50,000 +
                            University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <Link to="/contact-us">Click here</Link> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. Vishwajit Joshi</span>
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">
                              2. Avinash Chandrachud
                            </span>
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">3. Sahil Kulkarni</span>
                            Director, Writor, Academician
                          </li>
                          <li>
                            <span className="fw-bold">4. Vishal Sadafule</span>
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">5. Pranav Badve</span>
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">6. Sagar Raut</span>
                            Sound Designer
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <Link to="/facilities">Click here</Link> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Prof. Mahesh Harbak</span> +91
                    9834602449
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
