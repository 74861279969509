import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
import { ButtonShortProgramsBrochure } from "../../../Components/Common/Button/Button";

export const ShortPrograms = () => {
  const el = useRef();

  return (
    <>
      <Header />
      <main>
        <section className="job__detail-top pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white ">
                    Short Programs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8">
                <div className="job__detail-wrapper">
                  <div className="job__detail-content">
                    <h2>Program Highlights </h2>
                    <ul>
                      <li>
                        To offer the young aspiring film professionals an
                        interdisciplinary and dynamic milieu, where they can
                        develop a comprehensive and critical awareness of all
                        aspects of filmmaking.
                      </li>
                      <li>To develop strong and independent</li>
                      <li>
                        To provide the fundamental aesthetic values of the art
                        of cinema
                      </li>
                      <li>
                        To provide a platform for the showcase of their art.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                <div className="job__detail-sidebar">
                  <h5 className="mb-3">Speak with Program In-charge</h5>
                  <ul>
                    <li>
                      <span>Prof. Mahesh Harbak</span> +91 9834602449
                    </li>
                  </ul>
                  <h5 className="mt-5 mb-3">Fees Structure</h5>
                  <ul>
                    <li>
                      <span>Duration:</span> 6 Months (Regular)
                    </li>
                    <li>
                      <span>Annual Fees:</span> 50,000/- + University Fees
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        View More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5 pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                <h2 className="sec-title animation__char_come">
                  {" "}
                  Certificate Course in Filmmaking
                </h2>
              </div>
              <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                <div className="sec-text">
                  <p className="mb-3">
                    Students can opt for one Major out of the following
                  </p>
                </div>
              </div>
            </div>
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics in lighting <br /> for films &amp; Theater
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of <br /> Sound Designing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of DI <br /> &amp; Colourist
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of <br /> Editing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of Acting <br /> for Film
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of VFX <br /> &amp; Animation
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of Carpentry <br /> &amp; Set Designing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of <br /> Costume Designing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/contact-us">
                      <h3 className="ps-title">
                        Basics of Make-up <br /> Artist
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to=" /contact-us">
                      <h3 className="ps-title">
                        Basics of <br /> Screenplay Writing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <ButtonShortProgramsBrochure />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
