import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
export const FilmEditingShortPrograms = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-editing pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/mgmu.jpg?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    Film Editing Short Programs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-sub-title title-anim">
                    Basics of Film Editing
                  </h2>
                  <h2 className="sec-title">6 Months Certificate Program </h2>

                  <div
                    className="job__detail-contents"
                    style={{ padding: "20 !important;" }}
                  >
                    <p>
                      Our 6 Months Basic Weekend Certificate Program is one step
                      ahead of the Introductory Certificate Program. Specially
                      designed for students who want to join the Film Industry
                      at the Chief Assistant level. Editor of Feature films, TV
                      Serials, and Web Series requires a reliable and skilled
                      person who can handle all the key managerial as well as
                      creative aspects.{" "}
                    </p>
                    <p>
                      In this short but intensive program, students will adapt
                      every skill required to become a good Chief Assistant
                      Editor.{" "}
                    </p>
                    <p>
                      MGMU School of Film Arts is the perfect echo system for
                      budding filmmakers, actors, and technicians.
                    </p>
                    <div className="row mt-5">
                      <div className="col-xxl-12">
                        <div className="faq__list-6">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Program Highlights
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    We follow the principle of “Learning by
                                    doing” while providing In-depth, hands-on,
                                    practical training
                                  </li>
                                  <li>
                                    Training by practising film & TV industry
                                    tutors
                                  </li>
                                  <li>
                                    Easy access to Dubbing, Editing Studios
                                  </li>
                                  <li>
                                    Training with the help of Complete
                                    Professional Filmmaking Setup
                                  </li>
                                  <li>
                                    Masterclass by Actors, Directors,
                                    Technicians from Industry
                                  </li>
                                  <li>
                                    Acting in minimum of two short
                                    films/semester compulsory
                                  </li>
                                  <li>
                                    Chance to act in a Full Length Feature Film
                                    of Graduation Students
                                  </li>
                                  <li>
                                    Screening of Short Films made by you at
                                    Aurangabad International Film Festival
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Fees & Program Details
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <span className="fw-bold">Duration</span>: 1
                                    Year (2 Semesters)
                                  </li>
                                  <li>
                                    <span className="fw-bold">Internship</span>:
                                    Compulsory for 2 weeks
                                  </li>
                                  <li>
                                    <span className="fw-bold">Annual Fees</span>
                                    : Rs. 75,000/- + University Fees
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>Syllabus</Accordion.Header>
                              <Accordion.Body>
                                <p>
                                  <Link to="/contact-us">Click here</Link> to
                                  ask for syllabus
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                Faculty & Mentors
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <span className="fw-bold">
                                      1. Mukta Barve
                                    </span>
                                    Actor, Producer
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      2. Yateen Karyekar
                                    </span>{" "}
                                    Actor
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      3. Chandrakant Kulkarni
                                    </span>
                                    Producer, Director
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      4. Shiv Kadam
                                    </span>
                                    Director, Writor, Academician
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      5. Jayprad Desai
                                    </span>{" "}
                                    Producer, Director
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      6. Dnyanesh Zoting
                                    </span>{" "}
                                    Producer, Director
                                  </li>
                                  <li>
                                    <span className="fw-bold">
                                      7. Nandu Bhure
                                    </span>
                                    Actor, Trainer
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>
                                Core Facilities
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>1. Film City</li>
                                  <li>
                                    2. Super 35mm Cameras, Cine Lights, Cine
                                    Lenses
                                  </li>
                                  <li>3. Dubbing Studio</li>
                                  <li>4. Shooting Floors</li>
                                  <li>5. Shooting Locations</li>
                                  <li>6. Filmmaking Equipments</li>
                                  <li>7. Costume Lab</li>
                                  <li>8. Make-up Lab</li>
                                  <li>
                                    9. <a href="facilities">Click here</a> to
                                    check other facilities{" "}
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                        <div className="mt-5">
                          <h4 className="text-white mb-2">
                            Speak with the Program Incharge
                          </h4>
                          <p>
                            <span className="fw-bold">Prof. Mahesh Harbak</span>{" "}
                            +91 9834602449
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
