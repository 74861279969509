import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
export const DiplomaInFilmDirection = () => {
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-film-direction pt-150 pb-100"
          style={{
            background:
              "url(assets/imgs/bg/film-direction.png?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title  animation__char_come mt-5 text-white">
                    Diploma In Film Direction
                  </h2>
                  <p className="text-white">
                    Create easy access to the Film Industry
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title sub-section-title">
                    Diploma with Major in Direction
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Are you new to filmmaking? This intensive Diploma Program
                      is the only one of its kind to provide the opportunity to
                      shoot on Super 35mm cameras, Cine Lenses and complete
                      professional Filmmaking facilities.
                    </p>
                    <p>
                      In two semesters, you will take two short film project
                      from script to screen, gaining a complete and hands on
                      knowledge practically in the important filmmaking
                      principles and learn the cutting edge skills that equip
                      you for an entry level job in film, TV, Advertising and
                      web industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Film Direction student will excel in his craft only when
                      he will master in all the filmmaking elements. An Director
                      must know the camera and Lenses, Lighting Patterns,
                      Editing, Dubbing, Vfx. Hence we provide all standard
                      filmmaking facilities to the Direction student from day
                      one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>In-depth, hands-on, practical training</li>
                          <li>
                            Training by practising film & TV industry tutors
                          </li>
                          <li>Easy access to Dubbing, Editing Studios</li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Masterclass by Actors, Directors, Technicians from
                            Industry
                          </li>
                          <li>
                            Acting in minimum of two short films/semester
                            compulsory
                          </li>
                          <li>
                            Chance to act in a Full Length Feature Film of
                            Graduation Students
                          </li>
                          <li>
                            Screening of Short Films made by you at Aurangabad
                            International Film Festival
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 1 Year (2
                            Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 2 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000/- + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <Link to="/contact-us">Click here</Link> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey="4">
                      <Accordion.Header>Student Feedback</Accordion.Header>
                      <Accordion.Body>
                        {/* Insert the provided student feedback content here */}
                    {/* </Accordion.Body>
                    </Accordion.Item> */}

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span class="fw-bold">1. N. Chandra</span>
                            Producer, Director
                          </li>
                          <li>
                            <span class="fw-bold">2. Chandrakant Kulkarni</span>
                            Producer, Director
                          </li>
                          <li>
                            <span class="fw-bold">3. Nitin Baid</span>
                            Editor
                          </li>
                          <li>
                            <span class="fw-bold">4. Shiv Kadam</span>
                            Director, Writor, Academician
                          </li>
                          <li>
                            <span class="fw-bold">5. Jayprad Desai</span>
                            Producer, Director
                          </li>
                          <li>
                            <span class="fw-bold">6. Dnyanesh Zoting</span>
                            Producer, Director
                          </li>
                          {/* <li>
                              <span class="fw-bold">7. Jaee Kadam</span>
                              Cinematographer, Executive Producer
                            </li> */}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Film City</li>
                          <li>
                            2. Super 35mm Cameras, Cine Lights, Cine Lenses
                          </li>
                          <li>3. Dubbing Studio</li>
                          <li>4. Shooting Floors</li>
                          <li>5. Shooting Locations</li>
                          <li>6. Filmmaking Equipments</li>
                          <li>7. Costume Lab</li>
                          <li>8. Make-up Lab</li>
                          <li>
                            9. <Link to="/facilities">Click here</Link> to check
                            other facilities{" "}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Prof. Mahesh Harbak</span> +91
                    9834602449
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
